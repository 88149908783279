<script setup lang="ts">
import { computed } from 'vue'
import { useStoryblokHelpers } from '~~/composables/useStoryblokHelpers'
import type { UiButton } from '~~/types/storyblok/ui'

const props = defineProps<{
  blok: UiButton
}>()

const { isStoryblokEditor } = useStoryblokHelpers()

const sizeClass = computed(() => {
  return props?.blok?.size ? `button--${props?.blok?.size}` : 'button--sm'
})

const themeClass = computed(() => {
  return props?.blok?.theme ? `button--${props?.blok?.theme}` : 'theme-blue'
})

const url = computed(() => {
  switch (props.blok.link.linktype) {
    case 'story':
      return props.blok.link?.story?.full_slug
        ? '/' + props.blok.link.story?.full_slug
        : '/'
    case 'url':
    case 'asset':
      return props.blok.link.url
    case 'email':
      return 'mailto:' + props.blok.link.email
    default:
      return '#'
  }
})

const anchor = computed(() => {
  return props?.blok?.link?.anchor ? `#${props.blok.link.anchor}` : ''
})

const useIcon = computed(() => {
  switch (props.blok.link.linktype) {
    case 'story':
      return 'arrow_right_alt'
    case 'url':
    case 'asset':
      return 'north_east'
    case 'email':
      return 'outgoing_mail'
    default:
      return 'arrow_right_alt'
  }
})

const linkTo = computed(() => {
  return isStoryblokEditor.value ? '' : url.value + anchor.value
})
</script>

<template lang="pug">
NuxtLink(
  :to="linkTo"
  class="button"
  :class="[sizeClass, themeClass]"
  v-editable="blok"
)
  span {{ blok.label }}
  MaterialSymbol(v-if="!props.blok.hideIcon") {{ useIcon }}
</template>
